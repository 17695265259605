.table {
    margin-top: 20px;
    overflow: scroll;
    height: 400px;
    background-color: white;
    color: #4E4E4E;
}

.table tr {
    display: flex;
    justify-content: space-between;
}

.table td {
    padding: 0.5rem;
}

.table tr:nth-last-of-type(odd){
    background-color: #e2f8f9;
}