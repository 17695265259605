.infobox {
    flex: 1;
}
.infobox:hover {
    -webkit-box-shadow: inset 1px 0px 5px 0px rgba(148,148,148,1);
    -moz-box-shadow: inset 1px 0px 5px 0px rgba(148,148,148,1);
    box-shadow: inset 1px 0px 5px 0px rgba(148,148,148,1);
}

.infobox:not(:last-child) {
    margin-right: 10px;
}

.infobox_title {

}

.infobox_cases {
    color: #57AEB0;
    font-weight: 600;
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
}
.infobox_total {
    color: #6c757d;
    font-weight: 700 !important;
    font-size: 0.8rem !important;
    margin-top: 15px !important;
}