body {
    background-color: #F6F6F6;
}

.app {
    display: flex;
    justify-content: space-evenly;
}

.app_header > h1 {
    color: #57AEB0;
    font-size: 2rem;
}

.app_header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.app_stats {
    display: flex;
    justify-content: space-between;
}
.app_left {
    flex: 0.9;
}
.app_right {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.app_right .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.app_graph {
    flex-grow: 1;
}

@media (max-width: 990px) {
    .app {
        flex-direction: column;
    }
    .app_header {
        margin: 5px;
    }
    .infobox {
        margin: 5px;
    }
}